import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import "./Welcome.css";
import Button from "../../components/Button/Button";
import Loader from "../../components/Loader/Loader";
import poster from "../../assets/poster.mp4";

const Welcome = ({ setStep, voiceValue, setVoiceValue }) => {
  const [isLoading, setLoading] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    axios
      .get("https://api.juvs-tarot.ru/welcome")
      .then((res) => {
        videoRef.current.src = res.data.video;
        setLoading(false);
      })
      .catch((error) => {
        alert(error.message)
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    videoRef.current.volume = voiceValue / 100;
  }, [voiceValue]);

  return (
    <div className="welcome">
      {isLoading && <Loader />}
      <input
        type="range"
        min="1"
        max="100"
        value={voiceValue}
        onChange={(e) => {
          setVoiceValue(e.target.value);
          videoRef.current.volume = e.target.value / 100;
        }}
      />
      <video
        ref={videoRef}
        src={poster}
        playsInline
        autoPlay
        controls={false}
      ></video>
      <div className="welcome__form">
        <Button
          onClick={() => {
            setStep(2);
          }}
        >
          Начать таинство
        </Button>
      </div>
    </div>
  );
};

export default Welcome;
