import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Button from "../../components/Button/Button";
import Loader from "../../components/Loader/Loader";
import poster from "../../assets/poster.mp4";

const QuestionPage = ({
  setQuestion,
  setStep,
  name,
  voiceValue,
  setVoiceValue,
}) => {
  const videoRef = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    axios
      .post("https://api.juvs-tarot.ru/submit-name", { name })
      .then((res) => {
        videoRef.current.src = res.data.video;
        setLoading(false);
      })
      .catch((error) => {
        alert(error.message)
        setLoading(false);
      });
  }, [name]);

  useEffect(() => {
    videoRef.current.volume = voiceValue / 100;
  }, [voiceValue]);

  return (
    <div className="welcome">
      {isLoading && <Loader />}
      <input
        type="range"
        min="1"
        max="100"
        value={voiceValue}
        onChange={(e) => {
          setVoiceValue(e.target.value);
          videoRef.current.volume = e.target.value / 100;
        }}
      />
      <video playsInline src={poster} ref={videoRef} autoPlay></video>
      <div className="welcome__form">
        <input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && inputValue) {
              setQuestion(inputValue);
              setStep(4);
            }
          }}
          type="text"
          placeholder="Введите ваш вопрос"
        />
        <Button
          onClick={() => {
            if (!inputValue) {
              return;
            }
            setQuestion(inputValue);
            setStep(4);
          }}
        >
          Ок
        </Button>
      </div>
    </div>
  );
};

export default QuestionPage;
