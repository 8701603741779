import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Button from "../../components/Button/Button";
import Loader from "../../components/Loader/Loader";
import poster from "../../assets/poster.mp4";

const StartRitualPage = ({ setStep, setName, voiceValue, setVoiceValue }) => {
  const videoRef = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    axios.get("https://api.juvs-tarot.ru/start-ritual").then((res) => {
      videoRef.current.src = res.data.video;
      setLoading(false);
    }).catch(error => {
      alert(error.message)
      setLoading(false);

    });
  }, []);

  useEffect(() => {
    videoRef.current.volume = voiceValue / 100;
  }, [voiceValue]);

  return (
    <div className="welcome">
      {isLoading && <Loader />}
      <input
        type="range"
        min="1"
        max="100"
        value={voiceValue}
        onChange={(e) => {
          setVoiceValue(e.target.value);
          videoRef.current.volume = e.target.value / 100;
        }}
      />
      <video src={poster} ref={videoRef} playsInline autoPlay></video>
      <div className="welcome__form">
        <input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && inputValue) {
              setName(inputValue);
              setStep(3);
            }
          }}
          type="text"
          placeholder="Введите ваше имя"
        />
        <Button
          onClick={() => {
            if (inputValue === "") {
              return;
            }
            setName(inputValue);
            setStep(3);
          }}
        >
          Ок
        </Button>
      </div>
    </div>
  );
};

export default StartRitualPage;
