import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/Button/Button";
import "./SpreadPage.css";
import axios from "axios";
import Loader from "../../components/Loader/Loader";
import poster from "../../assets/poster.mp4";

const SpreadPage = ({ setStep, voiceValue, setVoiceValue, name, question }) => {
  const videoRef = useRef(null);
  const spreadRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [cards, setCards] = useState([]);
  const [interpretation, setInterpretation] = useState("");
  const [additionalAnswer, setAdditionalAnswer] = useState("");
  const [chatId, setChatId] = useState("");
  const [taskId, setTaskId] = useState("");
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    
    // Initial request to get chat_id
    axios
      .post("https://api.juvs-tarot.ru/submit-question", {
        name,
        question,
      })
      .then((res) => {
        setTaskId(res.data.chat_id); // Store chat_id for status checks
        checkStatus(res.data.chat_id); // Start checking status
      })
      .catch((error) => {
        alert(error.message);
        setLoading(false);
      });
  }, [name, question]);

  const checkStatus = (taskId) => {
    const interval = setInterval(() => {
      axios
        .post("https://api.juvs-tarot.ru/submit-question", {
          chat_id: taskId
        })
        .then((res) => {
          if (res.data.status === 'Created') {
            videoRef.current.src = res.data.video; // Set video source
            setCards(res.data.cards);
            setInterpretation(res.data.interpretation);
            setChatId(res.data.chat_id);
            setLoading(false);
            clearInterval(interval); // Clear interval once the status is 'Created'
          }
        })
        .catch((error) => {
          console.log(error);
          // clearInterval(interval); // Clear interval on error
          // setLoading(false); // Stop loading on error
        });
    }, 2000); // Check status every 3 seconds
  };

  useEffect(() => {
    videoRef.current.volume = voiceValue / 100;
  }, [voiceValue]);

  const onSubmit = () => {
    setLoading(true);

    axios
      .post("https://api.juvs-tarot.ru/additional- ", {
        chat_id: chatId,
        question: inputValue,
      })
      .then((res) => {
        console.log(res.data);
        setAdditionalAnswer(res.data.description);
        videoRef.current.src = res.data.video;
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false); // Ensure loading is set to false on error
      });
  };

  const saveAsPdf = () => {
    window.print();
  };

  return (
    <div ref={spreadRef} className={"spread" + (isLoading ? " loading" : "")}>
      {isLoading && <Loader />}
      <input
        type="range"
        min="1"
        max="100"
        value={voiceValue}
        onChange={(e) => {
          setVoiceValue(e.target.value);
          videoRef.current.volume = e.target.value / 100;
        }}
      />
      <video playsInline src={poster} ref={videoRef} autoPlay></video>

      <div className="spread__card">
        {cards.map((card) => (
          <img
            src={card.img}
            alt="card"
            className="spread__card-img"
            key={card.cardName}
          />
        ))}
      </div>
      <div
        className="spread__descr"
        dangerouslySetInnerHTML={{ __html: interpretation }}
      ></div>

      {additionalAnswer ? (
        <div className="spread__additional">
          <div
            className="spread__descr"
            dangerouslySetInnerHTML={{
              __html: additionalAnswer,
            }}
          ></div>
        </div>
      ) : (
        <div className="spread__form">
          <input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && inputValue) {
                onSubmit();
              }
            }}
            type="text"
            placeholder="Введите ваш вопрос"
          />
          <Button
            onClick={() => {
              if (inputValue) {
                onSubmit();
              }
            }}
          >
            Ок
          </Button>
        </div>
      )}
      <div className="spread__btns">
        <Button onClick={() => saveAsPdf()}>Сохранить PDF</Button>
        <Button onClick={() => setStep(3)}>Новый расклад</Button>
      </div>
    </div>
  );
};

export default SpreadPage;
