import { useState, useEffect, useRef } from "react";
import "./app.css";
import Welcome from "./pages/Welcome/Welcome";
import Button from "./components/Button/Button";
import QuestionPage from "./pages/QuestionPage/QuestionPage";
import SpreadPage from "./pages/SpreadPage/SpreadPage";
import StartRitualPage from "./pages/StartRitualPage/StartRitualPage";

function App() {
  const [firstCheck, setFirstCheck] = useState(true);
  const [step, setStep] = useState(1);
  const [name, setName] = useState("");
  const [question, setQuestion] = useState("");
  const [voiceValue, setVoiceValue] = useState(50);
  const [audioLoaded, setAudioLoaded] = useState(false);

  const backgroundAudioRef = useRef(null);

  // Функция для установки громкости фонового аудио
  useEffect(() => {
    if (backgroundAudioRef.current && audioLoaded) {
      const newVolume = Math.max(0, (voiceValue / 100) * 0.12); // 30% от текущей громкости
      backgroundAudioRef.current.volume = newVolume;
    }
  }, [voiceValue, audioLoaded]);

  const handleAudioLoad = () => {
    backgroundAudioRef.current.currentTime = 60;
    setAudioLoaded(true); // Устанавливаем флаг, когда аудио загружено
  };


  if (firstCheck) {
    return (
      <div className="cookies">
        <h1>Cookies и сторонний контент</h1>
        <span>
          Этот сайт использует cookies что-бы собирать информацию об ошибках
          чтобы улучшить сайт и исправить ошибки.
          <br /> Приймите cookies для хорошей оптимальной производительности.
        </span>
        <Button onClick={() => setFirstCheck(false)}>Принять</Button>
      </div>
    );
  }
  
  const audioSrc = "https://api.juvs-tarot.ru/assets/audio/background.mp3";
  
  return (
    <>
      {/* Добавляем аудиоплеер */}
      <audio
        ref={backgroundAudioRef}
        src={audioSrc}
        loop
        autoPlay
        onLoadedMetadata={handleAudioLoad} // Устанавливаем обработчик события
      />
      {/* Управление шагами */}
      {step === 1 && (
        <Welcome
          voiceValue={voiceValue}
          setVoiceValue={setVoiceValue}
          setStep={setStep}
        />
      )}
      {step === 2 && (
        <StartRitualPage
          voiceValue={voiceValue}
          setVoiceValue={setVoiceValue}
          setName={setName}
          setStep={setStep}
        />
      )}
      {step === 3 && (
        <QuestionPage
          voiceValue={voiceValue}
          setVoiceValue={setVoiceValue}
          setQuestion={setQuestion}
          name={name}
          setStep={setStep}
        />
      )}
      {step === 4 && (
        <SpreadPage
          voiceValue={voiceValue}
          setVoiceValue={setVoiceValue}
          setStep={setStep}
          question={question}
          name={name}
        />
      )}
    </>
  );
}

export default App;
