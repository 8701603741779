import React, { useState, useEffect } from "react";
import cls from "./Loader.module.css";

const Loader = () => {
  const [statusIndex, setStatusIndex] = useState(0);
  const statusMessages = [
    "Открываю завесу тайны...",
    "Соединяюсь с энергией Вселенной...",
    "Собираю силы для пророчества...",
    "Вглядываюсь в таинственные символы...",
    "Настраиваюсь на потоки судьбы...",
    "Вызываю силу звездных светил...",
    "Внимательно слушаю шепот карт...",
    "Проникаю в тайны неизведанного...",
    "Чувствую дыхание древних миров..."
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setStatusIndex((prevIndex) => (prevIndex + 1) % statusMessages.length);
    }, 3100); // меняем статус каждые 2 секунды

    return () => clearInterval(interval); // очищаем интервал при демонтировании компонента
  }, []);

  return (
    <div className={cls.loader__wrapper}>
      <span className={cls.loader}></span>
      <div className={cls.statusBar}>{statusMessages[statusIndex]}</div>
      <div className={cls.progressWrapper}>
        <div className={cls.progressBar}></div>
      </div>
    </div>
  );
};

export default Loader;
